import {useEffect, useState} from "react";

function getStorageValue(key: string, defaultValue: object | any[] | string | number | boolean) {
    // getting stored value
    const saved = localStorage.getItem(key);
    return saved === null ? defaultValue : JSON.parse(saved);
}

export function useLocalStorage(key: string, defaultValue: object | any[] | string | number | boolean) {
    const [localStorageValue, setLocalStorageValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(localStorageValue));
    }, [key, localStorageValue]);

    return [localStorageValue, setLocalStorageValue];
};