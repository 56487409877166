import {io, Socket} from 'socket.io-client';
import {ResultMessage} from "../interfaces/socketMessage";
import {DependencyList, useEffect} from "react";
import {PresenterState, VoterState} from "../interfaces/responseState";

// From https://socket.io/how-to/use-with-react

// "undefined" means the URL will be computed from the `window.location` object
const URL: any = process.env.NODE_ENV === 'production' ? 'https://gameshow.rds.unibas.ch' : 'http://localhost:5000';

export const vSocket: Socket<ServerToVoterEvents, ClientToServerEvents> = io(URL + '/voters', {
    autoConnect: false
});

export const pSocket: Socket<ServerToPresenterEvents, ClientToServerEvents> = io(URL + '/presenters', {
    autoConnect: false
});

export function useSocket(socketId: 'VOTER' | 'PRESENTER', onConnect: () => void, onDisconnect: () => void) {
    useEffect(() => {
        const socket = socketId === 'VOTER' ? vSocket : pSocket;

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        // no-op if the socket is already connected
        socket.connect();

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.disconnect();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Custom React Hook for managing event listeners related to a voter's state.
 *
 * @param stateEventListener - An optional callback function to handle 'STATE' events.
 * @param deps - An optional array of dependencies used to determine whether the (closure of the) event listener
 * might have changed and thus need to be re-registered.
 */
export function useVoterEventListeners(stateEventListener?: (a: VoterState) => void, deps?: DependencyList) {
    useEffect(() => {
        if (stateEventListener) vSocket.on('STATE', stateEventListener);

        return () => {
            if (stateEventListener) vSocket.off('STATE', stateEventListener);
        };
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Custom React Hook for managing event listeners related to a presenter's state and results.
 *
 * @param stateEventListener - An optional callback function to handle 'STATE' events.
 * @param resultAEventListener - An optional callback function to handle 'RESULT_A' events.
 * @param resultBEventListener - An optional callback function to handle 'RESULT_B' events.
 * @param deps - An optional array of dependencies used to determine whether the (closure of the) event listeners
 * might have changed and thus need to be re-registered.
 */
export function usePresenterEventListeners(
    stateEventListener?: (a: PresenterState) => void,
    resultAEventListener?: (a: ResultMessage) => void,
    resultBEventListener?: (a: ResultMessage) => void,
    deps?: DependencyList) {

    useEffect(() => {
        if (stateEventListener) pSocket.on('STATE', stateEventListener);
        if (resultAEventListener) pSocket.on('RESULT_A', resultAEventListener);
        if (resultBEventListener) pSocket.on('RESULT_B', resultBEventListener);

        return () => {
            if (stateEventListener) pSocket.off('STATE', stateEventListener);
            if (resultAEventListener) pSocket.off('RESULT_A', resultAEventListener);
            if (resultBEventListener) pSocket.off('RESULT_B', resultBEventListener);
        };
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}


// https://socket.io/docs/v4/typescript/

interface ServerToPresenterEvents {
    STATE: (a: PresenterState) => void;
    RESULT_A: (a: ResultMessage) => void;
    RESULT_B: (a: ResultMessage) => void;
}

interface ServerToVoterEvents {
    STATE: (a: VoterState) => void;
}

interface ClientToServerEvents {
}
