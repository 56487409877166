import React, {useEffect, useState} from 'react';
import {pSocket, usePresenterEventListeners, useSocket} from "../../utils/socket";
import {ResultMessage} from "../../interfaces/socketMessage";
import Countdown from "./Countdown";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {PresenterState} from "../../interfaces/responseState";
import {requestPresenterState} from "../../utils/restService";
import {switchPresenterState} from "../../utils/stateUtils";
import VoteBarGroup from "./VoteBarGroup";
import AnswerReveal from "./AnswerReveal";
import config from "../../utils/config";
import QrCode from "./QrCode";
import {AnimatePresence, motion} from "framer-motion";

const TRANSMISSION_DELTA_MS = 1000;

function Presenter() {
    const [isConnected, setIsConnected] = useState<boolean>(pSocket.connected);
    const [loading, setLoading] = useState(true);
    const [presenterState, setPresenterState] = useState<PresenterState>({state: 'BLACKOUT'});
    const [targetTime, setTargetTime] = useState<number>(0);
    const [voteDuration, setVoteDuration] = useState<number>(0);

    // presenterState might not contain the most current number of votes (if it was updated via socket message)
    // as changing the entire presenterState for each message is too expensive.
    // Therefore, we store votes in two separate states:
    const [progressA, setProgressA] = useState(0);
    const [progressB, setProgressB] = useState(0);

    function onConnect() {
        setIsConnected(true);
    }

    function onDisconnect() {
        setIsConnected(false);
    }

    useSocket('PRESENTER', onConnect, onDisconnect);

    function updateState(value: PresenterState) {
        if (value.state !== 'VOTING') {
            setTargetTime(0);
        }
        switchPresenterState(value,
            state => setPresenterState(oldState => ({...oldState, ...state})),
            state => {
                if (state.time_remaining === 0) {
                    setTargetTime(0);
                } else {
                    setTargetTime(new Date().getTime() + state.time_remaining - TRANSMISSION_DELTA_MS);
                }
                if (voteDuration === 0) setVoteDuration(state.vote_duration);
                setProgressA(state.votes_a);
                setProgressB(state.votes_b);
                return setPresenterState(state);
            },
            state => {
                setProgressA(state.votes_a);
                setProgressB(state.votes_b);
                return setPresenterState(state)
            },
            state => {
                setProgressA(state.votes_a);
                setProgressB(state.votes_b);
                return setPresenterState(state)
            },
            setPresenterState);
    }

    function onStateEvent(value: PresenterState) {
        updateState(value);
    }

    function onResultAEvent(value: ResultMessage) {
        setProgressA(value.votes);
    }

    function onResultBEvent(value: ResultMessage) {
        setProgressB(value.votes);
    }

    usePresenterEventListeners(onStateEvent, onResultAEvent, onResultBEvent, []);

    useEffect(() => {
        requestPresenterState()
            .then(result => {
                setLoading(false);
                updateState(result);
            })
    }, []);

    const presenterContent = switchPresenterState(presenterState,
        () => (
            <QrCode big/>
        ),
        state => (
            <motion.div
                key="vote"
                initial={{opacity: 0}}
                animate={{opacity: 1.0}}
                exit={{opacity: 0}}
                transition={{
                    duration: 1,
                    delay: 0.5
                }}>
                <div className="mb-prompt">
                    <h1 className="display-2 prompt-height">{state.prompt_text}</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={3} className="d-flex align-items-center justify-content-between flex-column">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <Countdown targetMs={targetTime} voteDuration={voteDuration}
                                           onTimeout={() => setTargetTime(0)}/>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <VoteBarGroup id="mainVoteBar" valueA={progressA} valueB={progressB}
                                          limit={config.MAX_EXPECTED_VOTES}></VoteBarGroup>
                        </Col>
                        <Col xs={3} className="align-self-center">
                            <AnimatePresence>
                                {targetTime > 0 && <QrCode/>}
                            </AnimatePresence>
                        </Col>
                    </Row>
                </Container>

            </motion.div>
        ),
        state => (
            <motion.div
                key="vote"
                initial={{opacity: 0}}
                animate={{opacity: 1.0}}
                exit={{opacity: 0}}
                transition={{
                    duration: 1,
                    delay: 0.5
                }}>
                <div className="mb-prompt">
                    <h1 className="display-2 prompt-height">{state.prompt_text}</h1>
                </div>
                <Container>
                    <Row>
                        <Col xs={3} className="d-flex align-items-center justify-content-center flex-column">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <AnswerReveal isA={state.solution_is_a}></AnswerReveal>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <VoteBarGroup id="mainVoteBar" valueA={progressA} valueB={progressB}
                                          limit={config.MAX_EXPECTED_VOTES} isReveal
                                          isA={state.solution_is_a}></VoteBarGroup>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        ),
        state => (
            <motion.div
                key="vote"
                initial={{opacity: 0}}
                animate={{opacity: 1.0}}
                exit={{opacity: 0}}
                transition={{
                    duration: 1,
                    delay: 0.5
                }}>
                <div className="mb-prompt">
                    <h1 className="display-2 prompt-height">{state.prompt_text}</h1>
                </div>
                <div className="d-flex justify-content-center overflow-x-hidden">
                    <VoteBarGroup id="mainVoteBar" valueA={progressA} valueB={progressB}
                                  limit={config.MAX_EXPECTED_VOTES} isReveal
                                  isA={state.solution_is_a} xPos={-200} initialXPos={175}></VoteBarGroup>
                    <VoteBarGroup id="preVoteBar" valueA={state.prevotes_a} valueB={state.prevotes_b}
                                  limit={config.MAX_EXPECTED_PREVOTES}
                                  isReveal isA={state.solution_is_a} xPos={200} initialXPos={"70vw"}></VoteBarGroup>
                </div>
            </motion.div>
        ),
        () => (<QrCode big/>)
    );

    return (
        <Container fluid className="text-center pt-standard">
            {loading ?
                <Spinner className="mt-5" data-testid="spinner"></Spinner> :
                <>
                    {!isConnected && <div className="position-absolute" style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: 'red'
                    }}></div>}
                    <AnimatePresence mode="wait">
                        {presenterContent}
                    </AnimatePresence>
                </>
            }
        </Container>
    );
}

export default Presenter;
