import {motion} from 'framer-motion';
import React from 'react';
import {ReactComponent as QrSVG} from '../../assets/images/qr.svg';

function QrCode({big = false}) {
    const size = big ? "qr-big" : "qr-small"
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1.0}}
            exit={{opacity: 0}}
            transition={{duration: 1}}>
            <QrSVG key={size} className={size + " mb-3"} data-testid={size}/>
        </motion.div>
    );
}

export default QrCode;