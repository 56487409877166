import {NavLink} from 'react-router-dom';
import '../assets/styles/navbar.css'
import {useAuth} from "./AuthProvider";
import {Container, Nav, Navbar} from "react-bootstrap";

const NavBar = () => {
    const [isLoggedIn, ] = useAuth();

    if (isLoggedIn) {  // for debugging: always show navbar
        return (
            <Navbar expand="md" bg="dark" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand>Artificial Art</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* "NavLink" here since "active" class styling is needed */}
                        <Nav.Link as={NavLink} to='/'>Voter</Nav.Link>
                        <Nav.Link as={NavLink} to='/present'>Presenter</Nav.Link>
                        <Nav.Link as={NavLink} to='/admin'>Admin</Nav.Link>
                    </Nav>
                    <Nav>
                        {isLoggedIn ?
                            <Nav.Link as={NavLink} to='/logout'>Log Out</Nav.Link> :
                            <Nav.Link as={NavLink} to='/login'>Log In</Nav.Link>
                        }
                    </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        );
    }

    return <> </>;
};

export default NavBar;