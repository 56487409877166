import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {AdminState, PresenterState, VoterState} from "../../interfaces/responseState";
import {requestAdminState, requestPresenterState, requestVoterState} from "../../utils/restService";
import {useAuth} from "../../components/AuthProvider";
import {Container, Spinner} from "react-bootstrap";
import StateOverview from "./StateOverview";

function Admin() {
    const [adminState, setAdminState] = useState<AdminState>({state: 'BLACKOUT', voter_count: 0});
    const [presenterState, setPresenterState] = useState<PresenterState>({state: 'BLACKOUT'});
    const [voterState, setVoterState] = useState<VoterState>({state: 'BLACKOUT'});
    const [isChecked, setIsChecked] = useState(false);  // whether to confirm progress VOTING
    const [loading, setLoading] = useState(true);
    const [isLoggedIn,] = useAuth();
    const navigate = useNavigate();

    function updateStates() {
        requestAdminState()
            .then(result => {
                setAdminState(result);
                setLoading(false); // we only wait for the admin state, as it is the most important one
            })
            .catch(error => alert(`Sorry, something went wrong: ${error.message}`)
            );


        requestPresenterState()
            .then(result => {
                setPresenterState(result);
            });

        requestVoterState()
            .then(result => {
                setVoterState(result);
            });
    }

    useEffect(() => {
        if (isLoggedIn) {
            updateStates();
        } else {
            navigate('/login');
        }
    }, []);

    return (
        <Container className="text-center pt-5">
            <h1>Admin</h1>
            {loading ? (
                <Spinner></Spinner>
            ) : (
                <>
                    <StateOverview adminState={adminState} presenterState={presenterState}
                                   voterState={voterState} updateStates={updateStates}></StateOverview>
                    <Outlet
                        context={[adminState, presenterState, voterState, updateStates, isChecked, setIsChecked] satisfies StateContextType}/>
                </>
            )}
        </Container>
    );
}

type StateContextType = [AdminState, PresenterState, VoterState, () => void, boolean, (isChecked: boolean) => void];

export function useContextStates() {
    return useOutletContext<StateContextType>();
}

export default Admin;
