import React from 'react';
import {motion} from 'framer-motion';
import config, {colors} from "../../utils/config";

type AnswerRevealProps = {
    isA: boolean
};

function AnswerReveal({isA}: AnswerRevealProps) {
    return (
        <div className="position-relative">
            <motion.div layout className="countdown-circle" data-testid="countdown-circle"
                        initial={{
                            backgroundColor: colors.GREY_1,
                        }}
                        animate={{
                            backgroundColor: [colors.GREY_1, colors.GREY_1, colors.GREY_1, isA ? colors.BLUE_2 : colors.GREEN_2],
                            scale: [1, 0, 0, 3]
                        }}
                        transition={{
                            delay: config.ANSWER_REVEAL_DELAY,
                            duration: config.ANSWER_REVEAL_DURATION,
                            times: [0, 0.2, 0.6, 1]
                        }}>
            </motion.div>
            <div className="countdown-circle countdown-circle-terminated" style={{backgroundColor: "transparent"}}>
                <motion.h1 className="revealed-answer" data-testid="revealed-answer"
                           initial={{
                               opacity: 0
                           }}
                           animate={{
                               opacity: 1.0
                           }}
                           transition={{
                               duration: 0.2,
                               delay: config.ANSWER_REVEAL_DURATION * 0.25 + config.ANSWER_REVEAL_DELAY
                           }}
                >{isA ? '1' : '2'}</motion.h1>
            </div>
        </div>
    );
}

export default AnswerReveal;