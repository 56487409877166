import {
    BlackoutState,
    PrepareState, PresenterState, RevealState, ShowPrevotesState,
    VoterState, VotingVoterState, VotingPresenterState
} from "../interfaces/responseState";


export function switchVoterState<T>(state: VoterState,
                                    onPrepare: (state: PrepareState) => T,
                                    onVoting: (state: VotingVoterState) => T,
                                    onBlackout: (state: BlackoutState) => T
): T {
    switch (state.state) {
        case 'PREPARED':
            return onPrepare(state as PrepareState);

        case 'VOTING':
            return onVoting(state as VotingVoterState);

        case 'BLACKOUT':
            return onBlackout(state as BlackoutState);
    }
}

export function switchPresenterState<T>(state: PresenterState,
                                        onPrepare: (state: PrepareState) => T,
                                        onVoting: (state: VotingPresenterState) => T,
                                        onReveal: (state: RevealState) => T,
                                        onShowPrevotes: (state: ShowPrevotesState) => T,
                                        onBlackout: (state: BlackoutState) => T
): T {
    switch (state.state) {
        case 'PREPARED':
            return onPrepare(state as PrepareState);
        case 'VOTING':
            return onVoting(state as VotingPresenterState);

        case 'REVEALING':
            return onReveal(state as RevealState);

        case 'SHOWING_PREVOTES':
            return onShowPrevotes(state as ShowPrevotesState);

        case 'BLACKOUT':
            return onBlackout(state as BlackoutState);
    }
}
