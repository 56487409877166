import {
    AdminState,
    PresenterState,
    StatusResponse,
    VoterState, VotingList
} from "../interfaces/responseState";
import {v4 as uuidV4} from "uuid";

const BASE_URL = (process.env.NODE_ENV === 'production' ? 'https://gameshow.rds.unibas.ch' : 'http://localhost:5000') + '/api';

export function requestVoterState(voterId: string = uuidV4()): Promise<VoterState> {
    return fetch(BASE_URL + `/get_state/voter/${voterId}`, getOpts(undefined, 'GET'))
        .then(deserialize);
}

export function requestPresenterState(): Promise<PresenterState> {
    return fetch(BASE_URL + '/get_state/presenter', getOpts(undefined, 'GET'))
        .then(deserialize);
}

export function requestAdminState(): Promise<AdminState> {
    return fetch(BASE_URL + '/get_state/admin', getOpts(undefined, 'GET'))
        .then(deserialize);
}

export function vote(userId: string, solutionIsA: boolean): Promise<StatusResponse> {
    const data = {user_uuid: userId, solution_is_a: solutionIsA};
    return fetch(BASE_URL + '/vote', getOpts(data))
        .then(deserialize);
}

/*     ADMIN     */
export function terminateVoting(): Promise<StatusResponse> {
    return fetch(BASE_URL + '/voting/terminate', getOpts())
        .then(deserialize);
}

export function progressVoting(): Promise<StatusResponse> {
    return fetch(BASE_URL + '/voting/next', getOpts())
        .then(deserialize);
}

export function addVoting(prompt: string,
                          votesA: number,
                          votesB: number,
                          prevotesA: number,
                          prevotesB: number,
                          solutionIsA: boolean): Promise<StatusResponse> {
    const data = {
        prompt: prompt,
        votes_a: votesA,
        votes_b: votesB,
        prevotes_a: prevotesA,
        prevotes_b: prevotesB,
        solution_is_a: solutionIsA
    };
    return fetch(BASE_URL + '/voting/add', getOpts(data))
        .then(deserialize)
        .catch(error => {
            console.error('Error from API call:', error.message);
        });
}

export function removeVoting(voteId: number | string): Promise<StatusResponse> {
    const data = {vote_id: voteId};
    return fetch(BASE_URL + '/voting/remove', getOpts(data))
        .then(deserialize);
}

export function prepareVoting(voteId: number, is_prevote = false): Promise<StatusResponse> {
    const data = {vote_id: voteId, is_prevote: is_prevote};
    return fetch(BASE_URL + '/voting/prepare', getOpts(data))
        .then(deserialize);
}

export function getVotingList(): Promise<VotingList> {
    return fetch(BASE_URL + '/voting/list', getOpts(undefined, 'GET'))
        .then(deserialize)
        .catch(error => {
            console.error('Error from API call:', error.message);
        });
}

export function checkAuthentication(): Promise<boolean> {
    return fetch(BASE_URL + '/authcheck', getOpts(undefined, 'GET'))
        .then(response => response.ok);
}

export function login(pw: string): Promise<boolean> {
    const data = {password: pw};
    return fetch(BASE_URL + '/login', getOpts(data))
        .then(response => response.ok);
}

export function logout(): Promise<boolean> {
    const data = {};
    return fetch(BASE_URL + '/logout', getOpts(data))
        .then(deserialize)
        .catch(error => {
            console.error('Error from API call:', error.message);
        });
}

function getOpts(data: object = {}, method: 'POST' | 'GET' = 'POST'): RequestInit {
    if (method === 'POST') {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
            credentials: 'include'
        }
    } else {
        return {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        }
    }
}

function deserialize(response: Response) {
    if (!response.ok) {
        return response.json().then(errorData => {
            const errorMessage = errorData.message; // The server sends error messages in JSON format with a 'message' field
            throw new Error(errorMessage); // Rethrow the error for further handling
        });
    }
    return response.json();
}
