import {motion} from 'framer-motion';
import React from 'react';
import config, {colors} from "../../utils/config";


function AnimatedVoteBar({value, limit, isReveal, isCorrect, id}: any) {
    const title = id === 1 ? 'Piece 2' : 'Piece 1';
    const progressP = Math.min(Math.max((value / limit * 100), config.ZERO_VOTES_PROGRESS_PERCENT), 100);
    const endP = 100 * 100 / progressP;

    const gradA = `linear-gradient(0deg, ${colors.BLUE_1} 0%, ${colors.BLUE_3} ${endP}%)`
    const gradB = `linear-gradient(0deg, ${colors.GREEN_1} 0%, ${colors.GREEN_3} ${endP}%)`
    const gradWrong = `linear-gradient(0deg, ${colors.GREY_1} 0%, ${colors.GREY_2} ${endP}%)`


    let gradient = id === 1 ? gradB : gradA;
    if (isReveal && !isCorrect) {
        gradient = gradWrong;
    }

    return (
        <div className="vote-bar-wrapper">
            <h1>{title}</h1>
            <motion.div className="vote-bar">
                <motion.div layout className={"vote-bar-progress"}
                            initial={false}
                            animate={{
                                background: gradient,
                                height: `${progressP}%`
                            }}
                            style={{background: gradient}}
                            data-testid={`vote-bar-progress-${id}`}
                ></motion.div>
            </motion.div>
            <h1 className="mt-3 display-2 mono-digits">{value}</h1>
        </div>
    );
}

export default AnimatedVoteBar;