import React from 'react';
import {AdminState, PresenterState, VoterState} from "../../interfaces/responseState";
import {Accordion, Button, Col} from "react-bootstrap";

type StateOverviewProps = {
    adminState: AdminState,
    presenterState: PresenterState,
    voterState: VoterState,
    updateStates: () => void
}

function StateOverview({adminState, presenterState, voterState, updateStates}: StateOverviewProps) {
    return (
        <Col md={{span: 4, offset: 4}}>
            <Accordion className="mb-5">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h5>Active States</h5></Accordion.Header>
                    <Accordion.Body className="overflow-auto text-start">
                        <p><strong>State (Admin):</strong> <br/> {JSON.stringify(adminState, null, 4)}</p>
                        <p><strong>State (Presenter):</strong> <br/> {JSON.stringify(presenterState, null, 4)}</p>
                        <p><strong>State (Voter):</strong> <br/> {JSON.stringify(voterState, null, 4)}</p>
                        <Button variant="secondary" onClick={updateStates}>Refresh</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Col>
    );
}

export default StateOverview;