import React from 'react';
import {Accordion, Button} from 'react-bootstrap';
import {Voting} from "../../interfaces/responseState";

type VotesListEntryProps = {
    eventKey: any;
    voting: Voting;
    isVoteActive: boolean;
    canDelete: boolean;
    prepare: (a: number, b: boolean) => void;
    remove: (a: number) => void;
}

function VotesListEntry({eventKey, voting, isVoteActive, canDelete, prepare, remove}: VotesListEntryProps) {
    return (
        <Accordion.Item eventKey={eventKey} className="text-start">
            <Accordion.Header><h5>{voting.vote_id}: {voting.prompt_text}</h5></Accordion.Header>
            <Accordion.Body>
                <p>Votes:</p>
                <ul>
                    <li>Piece 1: {voting.votes_a}</li>
                    <li>Piece 2: {voting.votes_b}</li>
                </ul>
                <p>Solution: {voting.solution_is_a ? 'Piece 1' : 'Piece 2'}</p>
                <Button disabled={isVoteActive} className="me-1" onClick={() => prepare(voting.vote_id, false)}>Run
                    Vote</Button>
                {/*<Button disabled={isVoteActive || (voting.prevotes_a > 0 || voting.prevotes_b > 0)} className="me-1" onClick={() => prepare(voting.vote_id, true)}>Run Orchestra*/}
                {/*    Vote</Button>*/}
                <Button disabled={!canDelete} variant="danger" className="me-1" onClick={() => remove(voting.vote_id)}>Delete
                    Vote</Button>

            </Accordion.Body>
        </Accordion.Item>
    );
}

export default VotesListEntry;
