import React, {useEffect, useState} from 'react';
import {Accordion, Button, Modal, Spinner} from "react-bootstrap";
import {getVotingList, prepareVoting, removeVoting} from "../../utils/restService";
import {Voting} from "../../interfaces/responseState";
import VotesListEntry from "./VotesListEntry";
import {useContextStates} from "./Admin";
import {useNavigate} from "react-router-dom";

function VotesList() {
    const [votings, setVotings] = useState<Voting[]>([]);
    const [adminState, , , updateStates] = useContextStates();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(-1);
    const [loading, setLoading] = useState(true);

    const voteId = adminState.vote_id ?? -1;
    const isVoteActive = voteId >= 0;
    const navigate = useNavigate();

    function loadList() {
        getVotingList()
            .then(result => {
                setVotings(result.votings);
                setLoading(false);
            });

    }

    useEffect(loadList, []);

    function prepareVote(voteId: number, is_prevote: boolean) {
        prepareVoting(voteId, is_prevote)
            .then(() => {
                updateStates();
                navigate(`vote/${voteId}`)
            })
            .catch(error => {
                alert(`An error occurred while trying to prepare the vote: ${error.message}`);
                updateStates();
            });
    }

    function remove(voteId: number) {
        if (voteId) {
            removeVoting(voteId)
                .then(() => {
                    updateStates();
                    loadList();
                })
                .catch(error => {
                    alert(`An error occurred while trying to remove the vote: ${error.message}`);
                    updateStates();
                });
        }
        setShowModal(false);
    }

    function openRemoveModal(voteId: number) {
        setModalData(voteId);
        setShowModal(true);
    }

    return (
        <>
            <h3>Registered Votes</h3>
            {loading ? (
                <Spinner></Spinner>
            ) : (
                <Accordion className="mb-5">
                    {votings.map((voting, index) => (
                        <VotesListEntry
                            key={voting.vote_id}
                            eventKey={index}
                            voting={voting}
                            isVoteActive={isVoteActive}
                            canDelete={voteId !== voting.vote_id}
                            prepare={prepareVote}
                            remove={openRemoveModal}
                        />
                    ))}
                </Accordion>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Vote</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete this voting?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => remove(modalData)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default VotesList;
