import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {progressVoting, terminateVoting} from "../../utils/restService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useContextStates} from "./Admin";
import VotingStateMap from "./VotingStateMap";

function VotingControl() {
    const voteId = useParams().voteId;
    const [adminState, , , updateStates, isChecked, setIsChecked] = useContextStates();
    const navigate = useNavigate();

    function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        setIsChecked(event.target.checked);
    }

    useEffect(() => {
        updateStates();
    }, [voteId]);

    function changeState() {
        if (isChecked && adminState.state === 'VOTING' && !window.confirm("Continue? This will end the vote!")) {
            return;
        }

        progressVoting()
            .then(() => {
                updateStates();
            })
            .catch(error => {
                alert(`An error occurred while progressing the vote: ${error.message}`);
                navigate('/admin');
            });
    }

    function terminate() {
        if (adminState.state !== 'BLACKOUT' && !window.confirm("Continue? This aborts a running vote!")) {
            return;
        }
        terminateVoting()
            .then(() => {
                updateStates();
                navigate('/admin');
            });
    }


    return (
        <>
            <h2 className="mt-5">Voting {voteId}</h2>
            <Link to="/admin"><Button variant="secondary" className="mb-5">Back to Dashboard</Button></Link>
            <br/>

            <label className="mb-3">
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                <span className="ps-1"> Confirm exiting VOTING</span>
            </label>
            <VotingStateMap stateName={adminState.state}></VotingStateMap>
            <br/>

            <Button className="me-1" onClick={changeState} disabled={adminState.state === 'BLACKOUT'}>Progress
                Vote</Button>
            <Button variant="danger" className="me-1" onClick={terminate}>Terminate Vote</Button>
        </>
    );
}

export default VotingControl;