// Contains global constants (-> relevant for more than 1 component)
const config = {
    ANSWER_REVEAL_DURATION: 4,
    ANSWER_REVEAL_DELAY: 0.5,
    MAX_EXPECTED_VOTES: 175,
    MAX_EXPECTED_PREVOTES: 25,
    ZERO_VOTES_PROGRESS_PERCENT: 1.25
};

export const colors = {
    GREEN_1: "#00b44bff",
    GREEN_2: "#3fc677ff",
    GREEN_3: "#a1e2bcff",
    GREY_1: "#9d9d9dff",
    GREY_2: "#bcbcbcff",
    GREY_3: "#fafafaff",
    BLUE_3: "#a6d3deff",
    BLUE_2: "#41a3bbff",
    BLUE_1: "#0385a6ff",
    PURPLE_2: "#3a3d99",
    PURPLE_1: "#23235D"
}

export default config;
