import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {colors} from "../../utils/config";

const MAX_SCALE = 2;

const colorList = [colors.GREEN_2, colors.BLUE_2]

function Countdown({targetMs, onTimeout, voteDuration}: any) {
    const [remainingMs, setRemainingMs] = useState(Math.max(targetMs - new Date().getTime(), 0));
    const scale = Math.max(MAX_SCALE * ((remainingMs - 500) / (voteDuration)) + 1, 1);
    const color = (remainingMs === 0) ? colors.GREY_1 : colorList[Math.round(remainingMs / 4000) % 2];

    const visibility = (remainingMs === 0) ? 'hidden' : 'visible';

    useEffect(() => {
        const interval = setInterval(() => {
            const remaining = targetMs - new Date().getTime()
            if (remaining <= 0) {
                setRemainingMs(0);
                clearInterval(interval);
                onTimeout();
            } else {
                setRemainingMs(remaining);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetMs]);

    return (
        <div>
            <motion.div className="countdown-circle" data-testid="countdown-circle"
                        initial={false}
                        animate={{
                            backgroundColor: color,
                        }}
                        transition={{
                            duration: 2,
                        }}
            >
                <motion.div className="countdown-circle countdown-circle-outer" data-testid="countdown-circle-outer"
                            initial={false}
                            animate={{
                                scale: scale,
                                visibility: visibility,
                                backgroundColor: color
                            }}
                            transition={{
                                backgroundColor: {
                                    duration: 2
                                },
                                duration: 0.3
                            }}
                >
                </motion.div>
                <h1 className="no-bottom-margin">{Math.ceil(remainingMs / 1000) || ''}</h1>
            </motion.div>
        </div>
    );
}

export default Countdown;
