import React, {useEffect, useState} from 'react';
import AnimatedVoteBar from "./AnimatedVoteBar";
import '../../assets/styles/animated_votebar.css'
import { motion } from 'framer-motion';
import config from "../../utils/config";

function VoteBarGroup({valueA, valueB, limit, isReveal, isA, xPos, initialXPos}: any) {
    const [showAnswer, setShowAnswer] = useState(isReveal);
    valueA = valueA ?? 0;
    valueB = valueB ?? 0;
    const maxValue = Math.max(limit, valueA, valueB);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isReveal && !showAnswer) {
                setShowAnswer(true);
            }
        }, 1000 * (config.ANSWER_REVEAL_DURATION * 0.75 + config.ANSWER_REVEAL_DELAY));

        return () => clearTimeout(timeout);
    }, [isReveal, showAnswer]);

    return (

        <motion.div
            className="d-flex flex-column"
            initial={{ x: initialXPos ?? 0 }}
            animate={{
                x: xPos ?? 0
            }}
            transition={{
                duration: 1.5,
                delay: 0.9}}
        >
            <div className="vote-bar-container">
                <AnimatedVoteBar
                    value={valueA}
                    limit={maxValue}
                    isReveal={showAnswer}
                    isCorrect={isA}
                    id={0}>
                </AnimatedVoteBar>

                <AnimatedVoteBar
                    value={valueB}
                    limit={maxValue}
                    isReveal={showAnswer}
                    isCorrect={!isA}
                    id={1}>
                </AnimatedVoteBar>
            </div>
        </motion.div>
    );
}

export default VoteBarGroup;