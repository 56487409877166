import React from 'react';
import './assets/App.scss';
import {Route, Routes} from "react-router-dom";
import Admin from "./pages/Admin/Admin";
import Presenter from "./pages/Presenter/Presenter";
import Voter from "./pages/Voter/Voter";
import PageNotFound from "./pages/PageNotFound";
import NavBar from "./components/NavBar";
import {Container} from "react-bootstrap";
import AddVoting from "./pages/Admin/AddVoting";
import VotingControl from "./pages/Admin/VotingControl";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";

// https://socket.io/how-to/use-with-react
function App() {

    return (
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/admin" element={<Admin/>}>
                    <Route path='add' element={<AddVoting />} />
                    <Route path='vote/:voteId' element={<VotingControl />} />
                    <Route index element={<AdminDashboard />} />
                </Route>
                <Route path="/present" element={<Presenter/>}/>
                <Route path="/" element={<Voter/>}/>
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </div>
    );
}

export default App;
