import React from 'react';

const states = ['PREPARED', 'VOTING', 'BLACKOUT'] // SHOWING_PREVOTES and REVEALING is skipped by backend.

function VotingStateMap({stateName}: any) {

    return (
        <>
        {states.map((state, ) => (
            <h4 key={state} className={state === stateName ? 'text-success' : 'text-secondary'}>{state}</h4>
            ))}
        </>
    );
}

export default VotingStateMap;