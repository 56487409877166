import React, {useEffect} from 'react';
import {useAuth} from "../../components/AuthProvider";
import {useNavigate} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {logout} from "../../utils/restService";

function Logout() {
    const [, setIsLoggedIn] = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        logout()
            .then(() => {
                setIsLoggedIn(false);
                navigate('/');
            })
    }, []);


    return (
        <Container className="text-center pt-standard">
            <Row className="vh-100 justify-content-center align-items-center">
                <Col xs="auto">
                    <Spinner animation="border" variant="primary" />
                </Col>
            </Row>
        </Container>
    );
}

export default Logout;