import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {addVoting} from "../../utils/restService";

interface FormData {
    prompt: string;
    votesA: number;
    votesB: number;
    prevotesA: number;
    prevotesB: number;
    solutionIsA: boolean;
}

function AddVoting() {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        prompt: '',
        votesA: 0,
        votesB: 0,
        prevotesA: 0,
        prevotesB: 0,
        solutionIsA: true,
    });
    const navigate = useNavigate();

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        const {id, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    function handleRadioChange(e: ChangeEvent<HTMLInputElement>) {
        setFormData((prevData) => ({
            ...prevData,
            solutionIsA: (e.target.id === 'radioA'),
        }));
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setShowModal(true);
    }

    function createVoting() {
        addVoting(formData.prompt, formData.votesA, formData.votesB, formData.prevotesA, formData.prevotesB, formData.solutionIsA)
            .then(() => {
                navigate('/admin');
            })
            .catch(error => alert(`Something went wrong while trying to create the vote: ${error.message}`));
        setShowModal(false);
    }

    return (
        <>
            <h2>Add Voting</h2>
            <Container className="text-start mt-4">
                <Link to="/admin"><Button variant="secondary" className="mb-5">Back to Dashboard</Button></Link>
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group className="mb-3" controlId="prompt">
                        <Form.Label>Prompt</Form.Label>
                        <Form.Control type="text" placeholder="Enter prompt" onChange={handleInputChange}
                                      value={formData.prompt}/>
                    </Form.Group>
                    {/*<Row className="mb-3">*/}
                    {/*    <Form.Group as={Col} sm controlId="votesA">*/}
                    {/*        <Form.Label>Initial Votes 1</Form.Label>*/}
                    {/*        <Form.Control type="number" placeholder="Votes 1" onChange={handleInputChange}*/}
                    {/*                      value={formData.votesA}/>*/}
                    {/*    </Form.Group>*/}
                    {/*    <Form.Group as={Col} sm controlId="votesB">*/}
                    {/*        <Form.Label>Initial Votes 2</Form.Label>*/}
                    {/*        <Form.Control type="number" placeholder="Votes 2" onChange={handleInputChange}*/}
                    {/*                      value={formData.votesB}/>*/}
                    {/*    </Form.Group>*/}
                    {/*</Row>*/}
                    {/*<Row className="mb-3">*/}
                    {/*    <Form.Group as={Col} sm controlId="prevotesA">*/}
                    {/*        <Form.Label>Initial Pre-Votes 1</Form.Label>*/}
                    {/*        <Form.Control type="number" placeholder="Pre-Votes 1" onChange={handleInputChange}*/}
                    {/*                      value={formData.prevotesA}/>*/}
                    {/*    </Form.Group>*/}
                    {/*    <Form.Group as={Col} sm controlId="prevotesB">*/}
                    {/*        <Form.Label>Initial Pre-Votes 2</Form.Label>*/}
                    {/*        <Form.Control type="number" placeholder="Pre-Votes 2" onChange={handleInputChange}*/}
                    {/*                      value={formData.prevotesB}/>*/}
                    {/*    </Form.Group>*/}
                    {/*</Row>*/}
                    <Row className="mb-3">
                        <Form.Label>Solution</Form.Label>
                        <Form.Group as={Col} sm controlId="formCheckA">
                            <Form.Check type="radio" label="1" id="radioA" name="group1" onChange={handleRadioChange}
                                        checked={formData.solutionIsA}/>
                        </Form.Group>
                        <Form.Group as={Col} sm controlId="formCheckB">
                            <Form.Check type="radio" label="2" id="radioB" name="group1" onChange={handleRadioChange}
                                        checked={!formData.solutionIsA}/>
                        </Form.Group>
                    </Row>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Voting</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to add this voting?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={createVoting}>
                        Add Voting
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
        ;
}

export default AddVoting;