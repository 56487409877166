import React from 'react';
import {Container} from "react-bootstrap";

function PageNotFound() {
    return (
        <Container className="text-center pt-standard">
            <h1 className="display-1 mb-5">Oops! Page Not Found</h1>
            <p>We're sorry, but the page you are looking for does not exist.</p>
            <p>Please check the URL or go back to the <a href="/">homepage</a>.</p>
        </Container>
    );
}

export default PageNotFound;