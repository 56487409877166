import React from 'react';

type VoteClosedProps = {
    hasVoted: boolean;
}

function VoteClosed({hasVoted}: VoteClosedProps) {
    if (hasVoted) {
        return (
            <>
                <h1 className="display-1">Artificial Art</h1>
                <h1>Thank you for your vote!</h1>
            </>
        );
    } else {
        return (
            <>
                <h1 className="display-1">Artificial Art</h1>
            {/*<h1>The voting period is over.</h1>*/}
            </>
        );
    }
}

export default VoteClosed;