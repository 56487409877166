import React from 'react';
import {Link} from "react-router-dom";
import VotesList from "./VotesList";
import {Card, Col, Row} from "react-bootstrap";
import {useContextStates} from "./Admin";

function AdminDashboard() {
    const [adminState] = useContextStates();
    const voteId = adminState.vote_id ?? -1;
    const isVoteActive = voteId >= 0;

    return (
        <>
            <h2 className="mb-3">Dashboard</h2>

            <Row className="mb-5 justify-content-md-center">
                <Col sm={4} className="mb-3">
                    <Card className={isVoteActive ? 'bg-success-subtle' : 'bg-dark-subtle'}>
                        <Card.Body>
                            <Card.Title>{"Control Vote" + (isVoteActive ? (" " + voteId) : "")}</Card.Title>
                            <Card.Text>Control the currently active vote.</Card.Text>
                            {isVoteActive ? (
                                    <Link className="stretched-link" to={`vote/${voteId}`}/>
                            ) : (
                                <Card.Text>(no vote is active)</Card.Text>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} className="mb-3">
                    <Card>
                        <Card.Body>
                            <Card.Title>Add Vote</Card.Title>
                            <Card.Text>Create a new vote with either a set initial number of votes or not.</Card.Text>
                            <Link className="stretched-link" to="add"/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <VotesList/>
        </>
    );
}

export default AdminDashboard;