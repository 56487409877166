import React, {createContext, useContext, useEffect, useState} from 'react';
import {checkAuthentication} from "../utils/restService";

const AuthContext = createContext<AuthContextType>([false, (isLoggedIn: boolean) => {
}]);

function AuthProvider({children}: any) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        isAuthenticated();
    }, []);

    function isAuthenticated() {
        checkAuthentication()
            .then(isAuth => {
                setIsLoggedIn(isAuth);
            })
            .catch(() => {
                setIsLoggedIn(false);
            })
    }

    return (
        <AuthContext.Provider value={[isLoggedIn, setIsLoggedIn]}>
            {children}
        </AuthContext.Provider>
    );
}

type AuthContextType = [boolean, (a: boolean) => void];

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;