import React from 'react';
import {Button} from "react-bootstrap";

function VoteButton({id, onVote}: any) {
    return (
        <Button size="lg" variant={id ? "btn btn-green" : "btn btn-blue"} className="mx-2 vote-btn" onClick={onVote}>
            {id ? '2' : '1'}
        </Button>
    );
}

export default VoteButton;