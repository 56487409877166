import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useAuth} from "../../components/AuthProvider";
import {useNavigate} from "react-router-dom";
import {login} from "../../utils/restService";

function Login() {
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/admin');
        }

    }, [isLoggedIn]);

    function handleLogin() {
        login(password)
            .then(hasSucceeded => {
                setIsLoggedIn(hasSucceeded);
                if (hasSucceeded) {
                    navigate('/admin');
                } else {
                    window.alert("Invalid Password.");
                }
            });
    }

    function isFormValid() {
        return password.trim() !== ''
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.key === 'Enter' && isFormValid()) {
            event.preventDefault();
            handleLogin();
        }
    }

    return (
        <Container className="text-center pt-standard">
            <h2>Admin Login</h2>
            <Row className="text-start">
                <Col md={{span: 6, offset: 3}}>
                    <Form autoComplete="off">

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={(e) => handleKeyPress(e)}
                            />
                        </Form.Group>

                        <Button variant="primary" onClick={handleLogin} disabled={!isFormValid()}>
                            Login
                        </Button>
                    </Form>

                </Col>
            </Row>
        </Container>
    );
}

export default Login;