import React, {useEffect, useState} from 'react';
import {useSocket, useVoterEventListeners, vSocket} from "../../utils/socket";
import VoteButton from "./VoteButton";
import {requestVoterState, vote} from "../../utils/restService";
import {VoterState} from "../../interfaces/responseState";
import {useLocalStorage} from "../../utils/hooks";
import {v4 as uuidV4} from "uuid";
import {switchVoterState} from "../../utils/stateUtils";
import VoteClosed from "./VoteClosed";
import {Col, Container, Row, Spinner} from "react-bootstrap";

function Voter() {
    const [, setIsConnected] = useState<boolean>(vSocket.connected);
    const [voteState, setVoteState] = useState<VoterState>({state: 'BLACKOUT'});
    const [voterId,] = useLocalStorage('voterId', uuidV4())
    const [targetTime, setTargetTime] = useState<number>(0);
    const [loading, setLoading] = useState(true);


    function onConnect() {
        setIsConnected(true);
    }

    function onDisconnect() {
        setIsConnected(false);
    }

    useSocket('VOTER', onConnect, onDisconnect);

    function updateState(value: VoterState) {
        if (value.state !== 'VOTING') {
            setTargetTime(0);
        }
        switchVoterState(value,
            setVoteState,
            state => {
                if (state.time_remaining === 0) {
                    setTargetTime(0);
                } else {
                    setTargetTime(new Date().getTime() + state.time_remaining);
                }
                return setVoteState(state);
            },
            setVoteState);

    }

    function onStateEvent(value: VoterState) {
        updateState(value);
    }

    useVoterEventListeners(onStateEvent, []);

    useEffect(() => {
        requestVoterState(voterId)
            .then(result => {
                updateState(result);
                setLoading(false);
            })
            .catch(() => alert("Sorry, something went wrong. Please check your internet connection."));
    }, [voterId]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (targetTime > 0) {
            const remaining = targetTime - new Date().getTime();
            timeout = setTimeout(() => {
                setTargetTime(0);
            }, remaining);

        }

        return () => clearTimeout(timeout);
    }, [targetTime]);

    function submitVote(solutionIsA: boolean) {
        vote(voterId, solutionIsA)
            .then(() => {
                setVoteState({state: 'VOTING', has_voted: true, time_remaining: 0, vote_duration: 0});
            })
            .catch(() => {
                alert("We were not able to process your vote. Please try again later.");
            });
    }

    const voterContent = switchVoterState(voteState,
        state => (
            <>
                <div className="mb-prompt">
                    <h1 className="display-1">{state.prompt_text}</h1>
                </div>
                <h3>Waiting for vote to start.</h3>
            </>
        ),
        state => {
            if (state.has_voted) {
                return <VoteClosed hasVoted={true}/>
            } else if (targetTime > 0) {
                return (
                    <>
                        <div className="mb-prompt">
                            <h1 className="display-1">{state.prompt_text}</h1>
                        </div>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <VoteButton id={0} onVote={() => submitVote(true)}/>
                                </Col>
                                <Col>
                                    <VoteButton id={1} onVote={() => submitVote(false)}/>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            } else {
                return (
                    <>
                        <VoteClosed hasVoted={false}/>
                    </>
                )
            }
        },
        () => (
            <VoteClosed hasVoted={false}/>
        )
    );


    return (
        <Container className="text-center pt-standard">
            {loading ? <Spinner className="mt-5" data-testid="spinner"></Spinner> :
                <>
                    {voterContent}
                    {/*<VoterDebugTools voterId={voterId} setVoterId={setVoterId}/>*/}
                </>
            }
        </Container>
    );
}

export default Voter;
